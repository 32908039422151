import {SurveyState} from 'client/common/store/reducers/survey/types';
import {Currency} from 'client/common/types';
import {_doRequest} from 'client/common/utils/api-client/base';
import {SuccessfulResponse} from 'client/common/utils/api-client/types';

export interface Orgs {
    id: number;
    name: string;
    description: string;
    created_at: string;
    owner_id: string;
    // countryid?: null;
    // currency?: null;
}

export interface OrgResponse {
    org: Orgs;
}

export interface OrgCreatedResponse {
    org: Pick<Orgs, 'id' | 'name' | 'description'>;
}

export interface OrgsResponse {
    orgs: Orgs[];
}

interface OrgSurvey {
    surveyId: number;
    slug: string;
    state: SurveyState;
    name: string;
    createdAt: string;
    box: any;
}

export interface OrgSurveyResponse {
    surveys: OrgSurvey[];
}

export const getOrg = async (orgId: number): Promise<SuccessfulResponse<OrgResponse>> => {
    const requestParams = {
        method: 'GET',
        prefix: '/api/v1',
        url: `/orgs/${orgId}`
    } as const;

    return _doRequest(requestParams);
};

export const getOrgs = async (): Promise<SuccessfulResponse<OrgsResponse>> => {
    const requestParams = {
        method: 'GET',
        prefix: '/api/v1',
        url: '/orgs'
    } as const;

    return _doRequest(requestParams);
};

export const createOrg = async ({
    name,
    description,
    adminEmail,
    currency,
    cfoId,
    serviceId
}: {
    name: string;
    description?: string;
    adminEmail?: string;
    currency?: Currency;
    cfoId?: string;
    serviceId?: string;
}): Promise<SuccessfulResponse<OrgCreatedResponse>> => {
    const requestParams = {
        method: 'POST',
        prefix: '/api/v1',
        url: '/orgs',
        data: {
            name,
            description,
            adminEmail,
            currency,
            cfoId,
            serviceId
        }
    } as const;

    return _doRequest(requestParams);
};

export const getOrgSurveys = async (
    orgId: number
): Promise<SuccessfulResponse<OrgSurveyResponse>> => {
    const requestParams = {
        method: 'GET',
        prefix: '/api/v1',
        url: `/orgs/${orgId}/surveys/`
    } as const;

    return _doRequest(requestParams);
};

export const chooseUserOrg = async (
    orgId: number | null
): Promise<SuccessfulResponse<OrgSurveyResponse>> => {
    const requestParams = {
        method: 'POST',
        prefix: '/api/v1',
        url: '/orgs/choose',
        data: {orgId: orgId ?? null},
        passQuery: false
    } as const;

    return _doRequest(requestParams);
};

export const migrateUserSurveysInOrg = async ({
    orgId,
    puid,
    migrationMode
}: {
    orgId: number;
    puid: string;
    migrationMode: 'access' | 'own';
}): Promise<SuccessfulResponse> => {
    const requestParams = {
        method: 'POST',
        prefix: '/api/v1',
        url: `/orgs/${orgId}/surveys/migrate`,
        data: {orgId, puid, migrationMode},
        passQuery: false
    } as const;

    return _doRequest(requestParams);
};

export * from './roles';
export * from './users';
