export enum LinkCampaign {
    Form = 'form',
    Statistics = 'statistics'
}

export enum LinkPage {
    Root = '/',
    QuicksurveyList = '/quicksurvey/list',
    CreativeList = '/creatives',
    BordomatikList = '/bordomatiks',
    Admin = '/admin',
    OldAdmin = '/surveys',
    Landing = '/landing',
    Quicksurvey = '/quicksurvey'
}

export function genPageLink(page: LinkPage, campaign?: LinkCampaign): string {
    const query = campaign
        ? `?utm_source=brandlift_direct&utm_medium=brandlift_direct&utm_campaign=${campaign}`
        : '';

    return `${page}${query}`;
}
