import React, {Fragment, useState} from 'react';
import b_ from 'b_';
import I18N from 'assets/i18n/brandlift-project';

import {useDevice} from 'client/common/hooks/device';
import {getCurrentOrg} from 'client/common/hooks/orgs';
import {genPageLink, LinkPage} from 'client/common/utils/header-link';
import {createUrl} from 'client/common/utils/history';
import {PortableDevices} from 'client/common/utils/device-width';
import {isWithinDates} from 'client/common/utils/date';
import {isYandexTeamDomain} from 'client/common/utils/yandex-team';
import {useUser} from 'client/common/contexts/user';

import MenuHamburgerIcon from './icons/menu-hamburger.svg';
import {HeaderProps} from './types';
import {OrgSelector} from './org-selector';
import {MenuLinksAsPopup, MenuLinksDesktop, MenuLinksDevice} from './menu-links';
import {HeaderButtonsDesktop, HeaderButtonsDevice} from './header-buttons';
import {UserPic} from './userpic';
import {meaningfulWrapperClassName} from './consts';

import './header2.css';

const i18n = I18N.common.header2;
const b = b_.with('header2');
const logoLink = 'logo-link';

// eslint-disable-next-line complexity
export default function Header(props: HeaderProps) {
    const user = useUser();

    const {
        className = '',
        linkCampaign,
        linkPage = LinkPage.Landing,
        buttons = ['help', 'create'],
        hideOrgSelector
    } = props;

    const isDevice = PortableDevices.includes(useDevice());

    const [isOpened, setIsOpened] = useState(false);
    const toggleMenu = () => {
        setIsOpened(!isOpened);
    };

    const closeMenu = () => {
        setIsOpened(false);
    };

    const logoClassName = isDevice ? 'logo-mobile' : 'logo';
    const lang = user?.lang ?? 'ru';
    const org = getCurrentOrg();

    return (
        <Fragment>
            <header
                className={`${b({'mobile-menu-opened': isOpened ? 'yes' : 'no'})} ${className}`}
            >
                <div className={b('row')}>
                    <div className={meaningfulWrapperClassName}>
                        <a
                            className={b(logoLink)}
                            href={createUrl({
                                link: genPageLink(linkPage, linkCampaign),
                                extraSearchParams: {org}
                            })}
                        >
                            <div className={b(logoClassName, {lang})} />
                        </a>
                        {isDevice ? (
                            <MenuLinksDevice
                                {...props}
                                isLangSwitchable={!isYandexTeamDomain}
                                buttons={buttons}
                            />
                        ) : (
                            <MenuLinksDesktop buttons={buttons} />
                        )}
                    </div>
                    {isYandexTeamDomain && !hideOrgSelector && <OrgSelector />}
                    {isDevice ? (
                        <HeaderButtonsDevice
                            {...props}
                            isLangSwitchable={!isYandexTeamDomain}
                            buttons={buttons}
                        />
                    ) : (
                        <HeaderButtonsDesktop
                            {...props}
                            isLangSwitchable={!isYandexTeamDomain}
                            buttons={buttons}
                        />
                    )}
                    <div className={b('userpic')}>
                        <UserPic />
                    </div>
                    {isDevice && (
                        <div className={b('menu-hamburger')} onClick={toggleMenu}>
                            <img src={MenuHamburgerIcon} />
                        </div>
                    )}
                </div>
                {isDevice && (
                    <div className={b('menu', {type: 'mobile', isOpened})}>
                        <MenuLinksAsPopup
                            {...props}
                            isLangSwitchable={!isYandexTeamDomain}
                            buttons={buttons}
                        />
                    </div>
                )}
                {isWithinDates({to: new Date(2024, 0, 9, 10)}) && (
                    <div className={b('warning')}>{i18n.warnings.newYear}</div>
                )}
            </header>
            <div className={b('overlay')} onClick={closeMenu} />
        </Fragment>
    );
}
